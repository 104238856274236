import { MenuItem } from "@components/layout/Menu";
import { Tab } from "@components/menu/Tab";
import { ImgSrc } from "@config/image";

type SupportMenuProps = {
  onClick: () => void;
};

const SupportMenu = ({ onClick }: SupportMenuProps) => {
  const menuItem: MenuItem = {
    title: "Help",
    icon: ImgSrc("/images/icons/support_white.svg"),
    type: "section",
    level: 1,
    selected: false,
    items: [
      {
        title: "Notehub Status",
        type: "link",
        href: "https://status.notehub.io",
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "Visit our Forum",
        type: "link",
        href: "https://discuss.blues.com",
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "Contact Support",
        type: "link",
        onClick,
        items: [],
        level: 2,
        selected: false,
      },
    ],
  };

  return (
    <>
      <div className="supportMenuContainer">
        <Tab {...menuItem} />
      </div>
      <style jsx>{`
        .supportMenuContainer {
          padding-right: 0.5rem;
        }
      `}</style>
    </>
  );
};

export default SupportMenu;
