/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/control-has-associated-label */
import Link from "next/link";
import CopyButton from "@components/blog/buttons/CopyButton";
import BadgePermission from "@components/doc/BadgePermission";
import DocTable from "@components/doc/DocTable";
import theme from "@config/theme";

type ValidPathParameter =
  | "projectUID"
  | "deviceUID"
  | "fleetUID"
  | "key"
  | "notefileID"
  | "notefileIDInbound"
  | "noteID"
  | "eventUID"
  | "monitorUID"
  | "routeUID"
  | "productUID";

interface RequestInformationProps {
  httpMethod: string;
  url: string;
  pathParameters?: ValidPathParameter[];
  minimumRole: "viewer" | "developer" | "owner";
  requiresAuth?: boolean;
}

const pathParameterToName = (param: ValidPathParameter) => {
  if (param === "notefileIDInbound") {
    return "notefileID";
  }
  return param;
};

const pathParameterToDescription = (param: ValidPathParameter) => {
  switch (param) {
    case "projectUID":
      return (
        <span>
          The <Link href="/api-reference/glossary#projectuid">ProjectUID</Link>{" "}
          of a Notehub project.
        </span>
      );
    case "deviceUID":
      return (
        <span>
          The <Link href="/api-reference/glossary#deviceuid">DeviceUID</Link> of
          a Notehub device. You may alternatively provide a device serial number
          by prefixing this argument with <code>sn:</code>, for example{" "}
          <code>sn:my-device</code>.
        </span>
      );
    case "fleetUID":
      return (
        <span>
          The <Link href="/api-reference/glossary/#fleetuid">FleetUID</Link> of
          a Notehub fleet.
        </span>
      );
    case "key":
      return <span>The key of the environment variable.</span>;
    case "notefileID":
      return (
        <span>
          The filename of the{" "}
          <Link href="/api-reference/glossary/#notefile">Notefile</Link>.
        </span>
      );
    case "notefileIDInbound":
      return (
        <span>
          The filename of the{" "}
          <Link href="/api-reference/glossary/#notefile">Notefile</Link>. The
          Notefile name must end in one of the following:
          <ul>
            <li>
              <code>.qi</code> for an incoming queue (Notehub to Notecard) with
              plaintext transport.
            </li>
            <li>
              <code>.qis</code> for an incoming queue (Notehub to Notecard) with
              encrypted transport.
            </li>
            <li>
              <code>.db</code> for a bidirectionally synchronized database with
              plaintext transport.
            </li>
            <li>
              <code>.dbs</code> for a bidirectionally synchronized database with
              encrypted transport.
            </li>
          </ul>
        </span>
      );
    case "noteID":
      return <span>The unique Note ID.</span>;
    case "eventUID":
      return (
        <span>
          The <Link href="/api-reference/glossary/#eventuid">EventUID</Link> of
          a Notehub project's event.
        </span>
      );
    case "monitorUID":
      return <span>The MonitorUID of an existing Notehub monitor.</span>;
    case "routeUID":
      return (
        <span>
          The <Link href="/api-reference/glossary#routeuid">RouteUID</Link> of
          an existing Notehub route.
        </span>
      );
    case "productUID":
      return (
        <span>
          The <Link href="/api-reference/glossary#productuid">ProductUID</Link>{" "}
          of a Notehub project.
        </span>
      );
    default:
      throw new Error(`Unknown path parameter: ${param}`);
  }
};

const RequestInformation = ({
  httpMethod,
  url,
  pathParameters,
  minimumRole,
  requiresAuth = true,
}: RequestInformationProps) => {
  const fullURL = `https://api.notefile.net${url}`;

  return (
    <>
      <div className="request-information-table">
        <DocTable>
          <tbody>
            <tr>
              <td>HTTP Method:</td>
              <td>
                <code>{httpMethod.toUpperCase()}</code>
              </td>
            </tr>
            <tr>
              <td>URL:</td>
              <td className="url-cell">
                <code>{fullURL}</code>

                <CopyButton text={fullURL} icon="clipboard" />
              </td>
            </tr>

            {pathParameters && pathParameters.length > 0 && (
              <tr>
                <td>Path Parameters:</td>
                <td>
                  <ul>
                    {pathParameters.map((param) => (
                      <li key={param}>
                        <code>{pathParameterToName(param)}</code> -{" "}
                        {pathParameterToDescription(param)}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}

            {requiresAuth && (
              <>
                <tr>
                  <td>
                    Minimum Notehub{" "}
                    <Link href="/notehub/notehub-walkthrough#collaborator-roles">
                      project-level role:
                    </Link>
                  </td>
                  <td>
                    <BadgePermission type={minimumRole} />
                  </td>
                </tr>
                <tr>
                  <td>Required HTTP Headers:</td>
                  <td>
                    <code>Authorization: Bearer &lt;access_token&gt;</code>,
                    where the access token is a Notehub API{" "}
                    <Link href="/api-reference/notehub-api/api-introduction">
                      bearer token
                    </Link>
                    .
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </DocTable>
      </div>
      <style jsx>{`
        .request-information-table :global(table) tr:first-child td {
          border-top: none;
        }
        .request-information-table :global(table),
        .request-information-table :global(table) td {
          border-color: ${theme.colors.snowBlue};
        }
        .url-cell {
          display: flex;
          gap: 0.5rem;
          align-items: center;
        }
        /* Otherwise the table renders incorrectly in Chrome and Safari */
        .url-cell code {
          overflow-wrap: anywhere;
        }
        .request-information-table ul {
          padding: 0 0 0 1rem;
          margin: 0;
        }
        .request-information-table li {
          margin-bottom: 1rem;
        }
        :global(.request-information-table ul ul) {
          margin-top: 1rem;
        }
        :global(.request-information-table ul li) {
          margin-bottom: 1rem;
        }
        .request-information-table li:last-child {
          margin-bottom: 0;
        }
      `}</style>
    </>
  );
};

export default RequestInformation;
