/* eslint-disable react/require-default-props */
import { useState, ReactElement } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import theme from "@config/theme";
import stripIndent from "strip-indent";
import { TabContent } from "@components/doc/Tabs";
import { prettyStr, formatJSONLine } from "@scripts/utils";

const debug = false;

// react syntax highlight languages
// using import for cLike caused errors because it's not exported in the esm library version, so using require instead
const arduino =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/arduino").default;
const bash =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/bash").default;
const cLike =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/c-like").default;
const cpp =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/cpp").default;
const go =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/go").default;
const html =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/htmlbars").default;
const js =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/javascript").default;
const json =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/json").default;
const python =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/python").default;
const sql =
  require("react-syntax-highlighter/dist/cjs/languages/hljs/sql").default;

const defaultLang = json; // Seems to work ok for languages that aren't supported by highlight.js

const languages: { friendlyName: string; abbr: string; module: any }[] = [
  { friendlyName: "Arduino", abbr: "arduino", module: arduino },
  { friendlyName: "Bash", abbr: "bash", module: bash },
  { friendlyName: "C/C++", abbr: "c", module: cLike },
  { friendlyName: "C/C++", abbr: "cpp", module: cpp },
  { friendlyName: "C/C++", abbr: "c++", module: cpp },
  { friendlyName: "Go", abbr: "go", module: go },
  { friendlyName: "HTML", abbr: "html", module: html },
  { friendlyName: "JS", abbr: "javascript", module: js },
  { friendlyName: "JS", abbr: "js", module: js },
  { friendlyName: "JSON", abbr: "json", module: json },
  { friendlyName: "JSONATA", abbr: "jsonata", module: json },
  { friendlyName: "Text", abbr: "plaintext", module: defaultLang },
  { friendlyName: "Python", abbr: "python", module: python },
  { friendlyName: "Bash", abbr: "sh", module: bash },
  { friendlyName: "SQL", abbr: "sql", module: sql },
  { friendlyName: "toml", abbr: "toml", module: defaultLang },
  { friendlyName: "Code", abbr: "unknown", module: defaultLang },
];

const englishFromAbbr: { [lang: string]: string } = {};

languages.forEach((l) => {
  SyntaxHighlighter.registerLanguage(l.abbr, l.module);
  englishFromAbbr[l.abbr] = l.friendlyName;
});

// CodeBlock's Properties are detailed below. They are defined as follows:
//
// <CodeBlock promptType="bash"
// input="time sleep 4"
// output={`
//     real    0m4.003s
//     user    0m0.001s
// `}/>
//
// Which will, intuitively set promptType="bash" input="time sleep 4" output="real
// 0m4.003s\nuser    0m0.001s" and all others undefined.
//
// OR
//
// ```ada
// Put_Line("Hello, World!");
// ```
//
// Which will set children=`Put_Line("Hello, World!");` className="language-ada"
// and all others undefine.
//
// Syntax highlight language reference:
// https://github.com/highlightjs/highlight.js/blob/master/SUPPORTED_LANGUAGES.md
//

type Props = {
  // if children is given (it is defined by the body of a fenced code block) its
  // contents will be shown where the output usually would be shown
  children?: string | ReactElement; // the body of a fenced code block
  // if className is given (it is equal to "language-{lang}" for a fenced code
  // block) (language-ada for example in a fence starting: ```ada) it will be
  // used for determining the language.
  className?: string;
  // Hide Copy Button
  hideCopyBtn?: boolean;
  // Hide Header
  hideHeader?: boolean;
  // if input is given, it will be shown with prompt and the copy button
  // will only copy the input
  input?: string;
  // utilize language if directly using CodeBlock component (ex language="javascript")
  language?: string;
  // trim whitespace from each line and remove newlines when copying to clipboard
  oneLineCopy?: boolean;
  // Returns Code Text String when Try Button clicked
  onTryClick?: (_code: string) => void;
  // if output is given, it will be shown below the input
  output?: string;
  // if promptType="serial", the input value will be displayed after a `>` prompt
  // iff promptType="bash",  the input value will be displayed after a `$` prompt
  // otherwise, the input value will be displayed after a `>` prompt
  promptType?: string;
};

// remove indent, leading and trailing whitespace from code string
const cleanCodeStr = (code: string) =>
  stripIndent(code)
    .replace(/^\n*/, "")
    .replace(/^\s+|\s+$/g, "");

type BtnTryProps = {
  pasted: boolean;
  btnClick: (_bTry: boolean) => void;
  noHeader?: boolean;
  textTryIt?: boolean;
  disabled?: boolean;
};

const BtnTry = ({
  pasted,
  btnClick,
  noHeader,
  textTryIt,
  disabled,
}: BtnTryProps) => (
  <>
    <button
      className={
        `btnCodeBlock try` +
        `${noHeader ? " noHeader" : ""}` +
        `${disabled ? " disabled" : ""}`
      }
      onClick={() => btnClick(true)}
      type="button"
      disabled={pasted || disabled}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {pasted ? "PASTED" : textTryIt ? "TRY IT" : "TRY"}
    </button>
    <style jsx>{`
      .btnCodeBlock.try {
        right: 4rem;
      }

      .btnCodeBlock.try.disabled {
        color: ${theme.colors.gray};
        cursor: default;
      }

      .btnCodeBlock.try.noHeader {
        top: 23px;
        right: 0px;
      }
    `}</style>
  </>
);

const CodeBlock = ({
  children: childrenProp,
  className: classNameProp,
  hideCopyBtn,
  input: inputProp,
  language,
  oneLineCopy: oneLineCopyProp,
  onTryClick,
  output: outputProp,
  promptType: promptTypeProp,
}: Props) => {
  let className = classNameProp;
  let input = inputProp;
  let output = outputProp;
  const oneLineCopy = oneLineCopyProp;
  const promptType = promptTypeProp;
  let children = childrenProp;

  // If the CodeBlock container is used with triple back ticks (```),
  // some of its props are on the child component.
  if (typeof children !== "string" && children?.props?.children) {
    className = children.props.className;
    children = children.props.children.toString();
  }

  const childrenAsText = typeof children === "string" ? children : "";

  if (promptType && childrenAsText && !input) {
    input = childrenAsText;
    children = undefined;
  }

  // find the line with the least amount of indent to remove that amount from
  // all lines, and remove any newlines at the beginning of the code block.
  // remove indent, also first and last \n in code string if present
  input = input ? cleanCodeStr(input) : undefined;
  output = output ? cleanCodeStr(output) : undefined;
  // 3 tick codeblock
  children = childrenAsText ? cleanCodeStr(childrenAsText) : undefined;

  // set language using className or language or use "json" by default if none set
  // json seems to colorize code adequately by default for now, maybe there's a better option
  const lang = (language || className || "unknown").replace(/language-/, "");
  if (!(lang in englishFromAbbr)) {
    throw new Error(
      `CodeBlock.tsx: unsupported language ${lang} - please add it`,
    );
  }

  // code copied/pasted to repl
  const [copied, setCopied] = useState(false);
  const [pasted, setPasted] = useState(false);

  // setup code syntax highlighter props
  const highlighterProps = {
    language: lang,
    useInlineStyles: false,
  };

  const handleTryClick = () => {
    const text = input || childrenAsText || output;
    if (!text || !onTryClick) return;
    onTryClick(text);
    setPasted(true);
    setTimeout(() => {
      setPasted(false);
    }, 2000);
  };

  const reduceToOneLine = (txt: string) =>
    txt
      .split("\n")
      .map((line) => line.trim())
      .join(" ");

  const prepareTextForCopy = (txt: string) =>
    oneLineCopy ? reduceToOneLine(txt) : txt;

  const handleCopyClick = () => {
    let text = input || childrenAsText || output;
    if (!text) return;
    text = prepareTextForCopy(text);
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      },
      () => {
        /* failure */
      },
    );
  };

  return (
    <>
      <TabContent
        title={
          englishFromAbbr[lang || promptType || ""] ||
          prettyStr(lang || "") ||
          englishFromAbbr.unknown
        }
      >
        {debug ? lang : null}
        <div
          className="codeBlockContainer" /* codeBlockContainer also in CodeTabs.tsx and ExampleResponse.tsx */
          data-search-exclude
        >
          <div className="headerContainer">
            {onTryClick && (
              <BtnTry
                pasted={pasted}
                btnClick={handleTryClick}
                textTryIt={Boolean(onTryClick)}
              />
            )}
            {!hideCopyBtn && (
              <button
                data-testid="btnCopy"
                className="btnCodeBlock copy"
                onClick={handleCopyClick}
                type="button"
                disabled={copied}
              >
                {copied ? "COPIED" : "COPY"}
              </button>
            )}
          </div>

          <div className={`codeContainer ${onTryClick ? " showTryBtn" : ""}`}>
            {input && (
              <div>
                <span className="promptChar">
                  {promptType === "bash" ? "$ " : "> "}
                </span>
                <SyntaxHighlighter {...highlighterProps}>
                  {input}
                </SyntaxHighlighter>
              </div>
            )}
            {output && (
              // explicit <CodeBlock> </CodeBlock>
              <SyntaxHighlighter {...highlighterProps}>
                {formatJSONLine(output)}
              </SyntaxHighlighter>
            )}
            {children && (
              <SyntaxHighlighter {...highlighterProps}>
                {children}
              </SyntaxHighlighter>
            )}
          </div>
          <style jsx>{`
            .codeBlockContainer {
              background-color: ${theme.colors.gray1};
              border-radius: 4px;
              padding: 0.75rem 0 0 0;
              margin-top: 1rem;
              margin-bottom: 1rem;
              font-size: ${theme.fonts.size.px14};
              letter-spacing: ${theme.fonts.letterSpacing.one};
            }

            .codeContainer {
              overflow-x: auto;
              padding: 1rem;
              position: relative;
              width: 100%;
              display: block;
            }

            .codeContainer.showTryBtn {
              width: calc(100% - 72px);
            }

            // first input (prompt) character > or $
            .promptChar {
              font-weight: bold;
              color: ${theme.colors.saffronYellow};
              white-space: pre;
              display: inline-block;
              margin: 2px;
              vertical-align: top;
            }

            .codeContainer :global(pre) {
              scrollbar-gutter: both;
              overflow-x: visible !important;
              padding: 0.25rem 0;
            }

            .headerContainer {
              position: relative;
              width: 100%;
            }

            .codeBlockContainer :global(.btnCodeBlock) {
              font-family: var(--font-barlow);
              letter-spacing: ${theme.fonts.letterSpacing.one};
              font-size: ${theme.fonts.size.px14};
              font-weight: bold;
              position: absolute;
              background-color: transparent;
              text-transform: uppercase;
              color: ${theme.colors.ultramarineTint};
              border: none;
              margin-right: 0.75em;
              cursor: pointer;
              z-index: ${theme.zIndex.main + 1};
              // invisible border to prevent layout shift on :focus
              border: 2px solid transparent;
            }
            .codeBlockContainer :global(.btnCodeBlock:focus) {
              border: 2px solid ${theme.colors.ultramarineTintLighter};
            }

            .codeBlockContainer :global(.btnCodeBlock.copy) {
              right: 0px;
            }

            .codeBlockContainer :global(.btnCodeBlock.copy:hover) {
              color: ${theme.colors.ultramarineTintLighter};
            }

            code {
              margin-right: 20px;
            }

            :global(.codeContainer::-webkit-scrollbar) {
              height: 14px !important;
            }

            :global(.codeContainer::-webkit-scrollbar-track-piece) {
              background-color: ${theme.colors.text} !important;
              border-radius: 4px !important;
            }

            :global(.codeContainer::-webkit-scrollbar-thumb) {
              background-color: ${theme.colors.mediumGray} !important;
              border-radius: 3px !important;
            }

            :global(.codeContainer::-webkit-scrollbar-thumb:hover) {
              background-color: ${theme.colors.gray} !important;
            }

            /* default code block styling for react-syntax-highlighter components (aka example request styling) */
            /* see ExampleResponse.tsx for example response code block styling */
            .codeBlockContainer :global(.hljs) {
              color: ${theme.colors.gray6};
              margin: 0;
              padding-right: 0.75em;
              line-height: 1.3;
              display: inline-block;
            }

            .codeBlockContainer :global(.hljs-emphasis) {
              font-style: italic;
            }

            .codeBlockContainer :global(.hljs-strong) {
              font-weight: bold;
            }

            .codeBlockContainer :global(.hljs-keyword),
            .codeBlockContainer :global(.hljs-literal),
            .codeBlockContainer :global(.hljs-symbol),
            .codeBlockContainer :global(.hljs-name) {
              color: ${theme.colors.teal};
            }

            .codeBlockContainer :global(.hljs-link) {
              color: ${theme.colors.teal};
              text-decoration: underline;
            }

            .codeBlockContainer :global(.hljs-subst),
            .codeBlockContainer :global(.hljs-function),
            .codeBlockContainer :global(.hljs-title),
            .codeBlockContainer :global(.hljs-params),
            .codeBlockContainer :global(.hljs-formula),
            .codeBlockContainer :global(.hljs-attr),
            .codeBlockContainer :global(.hljs-attribute),
            .codeBlockContainer :global(.hljs-builtin-name),
            .codeBlockContainer :global(.hljs-variable),
            .codeBlockContainer :global(.hljs-template-variable) {
              color: ${theme.colors.gray6};
            }

            .codeBlockContainer :global(.hljs-comment),
            .codeBlockContainer :global(.hljs-quote) {
              color: ${theme.colors.gray};
              font-style: italic;
            }

            .codeBlockContainer :global(.hljs-section),
            .codeBlockContainer :global(.hljs-built_in),
            .codeBlockContainer :global(.hljs-type) {
              color: ${theme.colors.notehubBlueTintLightest};
            }

            .codeBlockContainer :global(.hljs-bullet),
            .codeBlockContainer :global(.hljs-selector-tag),
            .codeBlockContainer :global(.hljs-selector-id),
            .codeBlockContainer :global(.hljs-selector-class),
            .codeBlockContainer :global(.hljs-selector-attr),
            .codeBlockContainer :global(.hljs-selector-pseudo) {
              color: ${theme.colors.lightGray};
            }

            .codeBlockContainer :global(.hljs-meta),
            .codeBlockContainer :global(.hljs-meta-keyword),
            .codeBlockContainer :global(.hljs-tag) {
              color: ${theme.colors.gray5};
            }

            .codeBlockContainer :global(.hljs-doctag),
            .codeBlockContainer :global(.hljs-regexp),
            .codeBlockContainer :global(.hljs-template-tag) {
              color: ${theme.colors.saffronYellow};
            }

            .codeBlockContainer :global(.hljs-number),
            .codeBlockContainer :global(.hljs-class) {
              color: ${theme.colors.saffronYellowTint};
            }

            .codeBlockContainer :global(.hljs-string),
            .codeBlockContainer :global(.hljs-meta-string) {
              color: ${theme.colors.saffronYellowTintLighter};
            }
          `}</style>
        </div>
      </TabContent>
    </>
  );
};

export default CodeBlock;
